import styled from "styled-components";
import { SvgRegistry } from "survey-core";
import ReactDOMServer from "react-dom/server";
import { ENPS_QUESTION_TYPE } from "./surveyJsConstants";
import Checkbox from "./icons/Checkbox";
import RadioButtonChecked from "./icons/RadioButtonChecked"
import ToggleOff from "./icons/ToggleOff";
import Subject from "./icons/Subject";
import LinearScale from "./icons/LinearScale";
import Promoter from "./icons/Promoter";

SvgRegistry.registerIconFromSvg("boolean", ReactDOMServer.renderToString(<ToggleOff />))
SvgRegistry.registerIconFromSvg("checkbox", ReactDOMServer.renderToString(<Checkbox />))
SvgRegistry.registerIconFromSvg("comment", ReactDOMServer.renderToString(<Subject />))
SvgRegistry.registerIconFromSvg("radiogroup", ReactDOMServer.renderToString(<RadioButtonChecked />))
SvgRegistry.registerIconFromSvg("rating", ReactDOMServer.renderToString(<LinearScale />))
SvgRegistry.registerIconFromSvg(ENPS_QUESTION_TYPE, ReactDOMServer.renderToString(<Promoter />))

const CustomSurveyJsStyles = styled.div`
  --primary: #1477f8;
  --background: #ffffff;
  --background-dim: #F5F6FA;
  --background-dim-light: #f5f6fa;
  --primary-foreground: #ffffff;
  --foreground: #1e004d;
  --base-unit: 8px;
  --sjs-primary-backcolor-dark: #005DEA;

  min-height: calc(100vh - var(--layoutNavigationHeight, 0px));
  height: calc(100vh - var(--layoutNavigationHeight, 0px));

  // Rating item selected color correction
  .sd-rating__item-text {
    color: inherit;
  }
`

export const CustomSurveyEditorStyles = styled(CustomSurveyJsStyles)<{
  $showTopBar: boolean
}>`
  .svc-tabbed-menu-wrapper {
    visibility: hidden;
  }

  .svc-top-bar {
    background-color: unset;

    @media (min-width: 1200px) {
      display: ${({ $showTopBar }): string => $showTopBar ? "flex" : "none"};
    }
  }

  .svc-side-bar__container-header, .svd-test-results {
    display: none;
  }
`

export const CustomResponseEditorStyles = styled(CustomSurveyJsStyles)`
  .sd-root-modern {
    min-height: inherit;
    padding-bottom: 100px;
  }
`
