import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import store from "./store";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import NoSurveys from "./NoSurveys";
import SurveyList from "./SurveyList";
import CustomButton from "components/button/Button";
import { APP_MAIN_FRONTEND_URL } from "constants/config";

type SurveyScreenProps = {};

const SurveyScreen: FC<SurveyScreenProps> = observer(() => {
  const params = useParams();
  const unique = params.unique;
  const guid = params.guid;
  const entityId = params.entity_id;

  const getMainURl = () => {
    return APP_MAIN_FRONTEND_URL;
  };

  useEffect(() => {
    if (guid !== null && guid !== undefined && guid !== "") {
      store.LoadSurveysByGuid(guid);
      return;
    }
    let idEntity = Number(entityId);
    if (
      unique !== null &&
      unique !== undefined &&
      unique !== "" &&
      !isNaN(idEntity) &&
      idEntity !== 0
    ) {
      store.LoadSurveysByUniqueId(unique, idEntity);
      store.getEntityById(idEntity);
      return;
    }
  }, []);

  return (
    <Container>
      <Content>
        <Form>
          {store.entity && (
            <Title>
              {store.entity.workspace_idNavName}: {store.entity.name}{" "}
            </Title>
          )}
          {store.reviewsList.length < 1 && store.surveysList.length < 1 ? (
            <NoSurveys />
          ) : (
            <SurveyList />
          )}
        </Form>
        <Label>
          Already using EngageRocket's platform? Sign in now to explore all surveys and reports
          available.
        </Label>
        <SignInInsteadButton
          variant="contained"
          onClick={() => {
            window.open(getMainURl(), "_blank").focus();
          }}
        >
          Sign in instead
        </SignInInsteadButton>
        <BottomLogoWrapper>
          <BottomLogo src="/assets/er-logo-pri_no-borders-light.png" />
        </BottomLogoWrapper>
      </Content>
      {/* {store.isLoadingSurveyList && <FullPageLoadingIndicator />} */}
    </Container>
  );
});

export default SurveyScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1074px;
  background-color: var(--colorNeutralBackground1);
  padding: 60px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Form = styled.div`
  width: 100%;
  max-width: 466px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  padding-bottom: 40px;
`;

const BottomLogoWrapper = styled.div`
  width: 100%;
  max-width: 466px;
  margin-top: 40px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 40px 0px 20px 0px;
  }
`;

const BottomLogo = styled.img`
  width: 184px;
  height: 29px;
`;

const SignInInsteadButton = styled(CustomButton)`
  width: 100% !important;
  max-width: 180px !important;
  height: 36px !important;
  margin-top: 28px !important;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  margin-top: 30px;
  max-width: 277px;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 10px 0px;
`;
